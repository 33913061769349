var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{class:{
      'favorite-list cursor-pointer': !_vm.isSelected,
      'favorite-list selected-list': _vm.isSelected,
    },on:{"click":_vm.invokeFilter}},[_c('div',{staticClass:"my-auto mr-2"},[_c('ImageComponent',{staticClass:"imgSize",attrs:{"path":_vm.favorite.image}})],1),_c('div',{class:{
        'my-auto': !_vm.isSelected,
        'my-auto text-white':
        _vm.isSelected,
      }},[_vm._v(" "+_vm._s(_vm.favorite.name)+" ")]),_c('div',{staticClass:"my-auto ml-auto",on:{"click":function($event){$event.stopPropagation();return _vm.deletePopup.apply(null, arguments)}}},[_c('StarIcon',{attrs:{"boolean":true,"color":_vm.isSelected
            ? 'white'
            : 'var(--settings-registration-current-event-button-background)'}})],1)]),_c('FavoritesPopup',{attrs:{"popup":_vm.popup,"favorite":_vm.favorite},on:{"closePopup":_vm.closePopup,"confirmPopup":_vm.deleteSelected}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }