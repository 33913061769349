<template>
  <section>
    <div
      @click="invokeFilter"
      :class="{
        'favorite-list cursor-pointer': !isSelected,
        'favorite-list selected-list': isSelected,
      }"
    >
      <div class="my-auto mr-2">
        <ImageComponent class="imgSize" :path="favorite.image" />
      </div>
      <div
        :class="{
          'my-auto': !isSelected,
          'my-auto text-white':
          isSelected,
        }"
      >
        {{ favorite.name }}
      </div>
      <div class="my-auto ml-auto" @click.stop="deletePopup">
        <StarIcon
          :boolean="true"
          :color="
            isSelected
              ? 'white'
              : 'var(--settings-registration-current-event-button-background)'
          "
        />
      </div>
    </div>
    <FavoritesPopup
      :popup="popup"
      :favorite="favorite"
      @closePopup="closePopup"
      @confirmPopup="deleteSelected"
    />
  </section>
</template>

<script>
import { EventBus } from '@/utils/event-bus';
import ImageComponent from '@/components/base-components/ImageComponent';
import FavoritesPopup from '@/components/base-components/Favorites/Popup';
import StarIcon from '@/components/icons/Star';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SideBarUpcomingListFavourite',
  components: {
    ImageComponent,
    StarIcon,
    FavoritesPopup,
  },
  data() {
    return {
      popup: false,
    };
  },
  props: {
    favorite: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getSelectedFavorite: 'getSelectedFavorite',
    }),
    isSelected(){
      return ((this.getSelectedFavorite.favorite_id === this.favorite.favorite_id) && (this.getSelectedFavorite.favorite_type === this.favorite.favorite_type))
    }
  },
  methods: {
    ...mapActions(['GET_DELETE_FAVORITE']),
    deletePopup() {
      this.popup = !this.popup;
    },
    closePopup() {
      this.popup = false;
    },
    deleteSelected() {
      this.GET_DELETE_FAVORITE({
        favorite_type: this.favorite.favorite_type,
        id: this.favorite.favorite_id,
      });
      this.closePopup();
      if (this.getSelectedFavorite?.favorite_id === this.favorite.favorite_id || this.getSelectedFavorite === 'all') {
        this.$store.commit('UPDATE_SELECT_FAVORITE', '');
        EventBus.$emit('refreshFavoritesSpecific');
      }
    },
    invokeFilter() {
      EventBus.$emit('resetPickedFavorite');
      EventBus.$emit('resetSearchFavorite');
      if (this.getSelectedFavorite?.favorite_id !== this.favorite.favorite_id) {
        if (this.favorite?.favorite_id) {
          this.$store.commit('UPDATE_SELECT_FAVORITE', this.favorite);
        }
        EventBus.$emit('refreshFavoritesSpecific');
      } else {
        this.$store.commit('UPDATE_SELECT_FAVORITE', '');
        EventBus.$emit('refreshFavoritesSpecific');
      }
      // EventBus.$emit('refreshFavoritesSpecific');
    },
  },
};
</script>

<style scoped lang="scss">
.favorite-list {
  text-align: start !important;
  margin-bottom: 1.5rem;
  display: flex;
}
.imgSize {
  max-height: 30px;
  max-width: 30px;
}
.cursor-pointer {
  cursor: pointer;
}

.selected-list {
  cursor: pointer;
  border-radius: 5px;
  padding: 2px;
  background-color: var(--settings-watch-button-background);
  margin-bottom: 1rem;
}
</style>
